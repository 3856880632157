var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"z-index-lg",attrs:{"persistent":"","max-width":"55%","scrollable":""},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"grey lighten-3 pa-0 ma-0",staticStyle:{"z-index":"10000 !important"}},[_c('v-list',{staticClass:"fill-width"},[_c('v-list-item',[_c('v-list-item-content',[_c('span',{staticClass:"text-h5 font-weight-bold primary--text"},[_vm._v("Detalles de Cobros")])]),_c('v-list-item-action',[_c('v-btn',{staticStyle:{"position":"absolute","right":"15px","top":"5px"},attrs:{"icon":""},on:{"click":function($event){return _vm.cerrar()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),_c('v-card-text',{staticClass:"px-0 pb-0"},[_c('v-data-table',{staticClass:"table-pagos elevation-1",attrs:{"item-key":"cod_pago","headers":_vm.headerPayment,"items":_vm.cobros,"footer-props":{itemsPerPageText: 'Mostrar'},"sort-by":"fecha_recibido","sort-desc":true},scopedSlots:_vm._u([{key:"item.cod_pago",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",class:{'py-8': !_vm.$vuetify.breakpoint.mobile }},[_c('v-icon',{attrs:{"left":"","color":item.status === 0 ? 'warning' : item.status === 1 ? 'icono' : item.status === 2 ? 'red' : ''},domProps:{"textContent":_vm._s(item.status === 0 ? 'mdi-alert' : item.status === 1 ? 'mdi-checkbox-marked-circle' : item.status === 2 ?'mdi-close-circle-outline' : '')}}),_c('span',{staticClass:"font-weight-medium",class:{'warning--text': item.status === 0, 'icono--text': item.status === 1, 'red--text': item.status === 2 },domProps:{"textContent":_vm._s(item.cod_pago)}})],1)]}},{key:"item.fecha_recibido",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium blue-grey--text"},[_vm._v(_vm._s(_vm._f("smartDate")(item.fecha_recibido)))])]}},{key:"item.cli_des",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text font-weight-bold",class:{'display': !_vm.$vuetify.breakpoint.mobile, 'subtitle-1': _vm.$vuetify.breakpoint.mobile },domProps:{"textContent":_vm._s(item.cli_des)}}),_c('span',{staticClass:"font-weight-bold ml-1",domProps:{"textContent":_vm._s('('+ item.co_cli + ')')}})]}},{key:"item.ven_des",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blue-grey--text font-weight-medium",class:{'display': !_vm.$vuetify.breakpoint.mobile, 'subtitle-1': _vm.$vuetify.breakpoint.mobile },domProps:{"textContent":_vm._s(item.ven_des)}}),_c('span',{staticClass:"font-weight-bold ml-1",domProps:{"textContent":_vm._s('('+ item.co_ven + ')')}})]}},{key:"item.monto",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"font-weight-bold black--text",staticStyle:{"font-size":"16px"}},[_vm._v("$"+_vm._s(_vm._f("FormatCurrency")(_vm._f("formatNumero")(item.monto))))])])]}},{key:"item.iconos",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"size":"20","color":"blue-grey"},domProps:{"textContent":_vm._s(item.metodo_pago === 'efectivo' ? 'mdi-cash-multiple' : item.metodo_pago === 'transferencia' ? 'mdi-bank-transfer': '')}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Metodo Pago: "+_vm._s(item.metodo_pago))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue-grey--text font-weight-medium mx-2",staticStyle:{"font-size":"18px"},domProps:{"textContent":_vm._s(item.moneda === 'dolares' ? '$' : item.moneda === 'bolivares' ? 'Bs.': '')}},'span',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Moneda: "+_vm._s(item.moneda))])])],1)]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center pa-4"},[_c('span',{staticClass:"blue-grey--text font-italic"},[_vm._v("No se encontraron Cobros Registrados")])])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }