<template>
  <v-dialog
    v-model="modalShow"
    persistent
    max-width="55%"
    scrollable
    class="z-index-lg"
  >
    <v-card tile>
      <v-card-title class="grey lighten-3 pa-0 ma-0" style="z-index: 10000 !important">
        <v-list class="fill-width">
          <v-list-item>
            <v-list-item-content>
              <span class="text-h5 font-weight-bold primary--text">Detalles de Cobros</span>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="cerrar()" style="position: absolute; right:15px; top: 5px">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-title>
      <v-card-text class="px-0 pb-0">
        <v-data-table
        class="table-pagos elevation-1"
        item-key="cod_pago"
        :headers="headerPayment"
        :items="cobros"
        :footer-props="{itemsPerPageText: 'Mostrar'}"
        sort-by="fecha_recibido"
        :sort-desc="true"
        >
        <!-- :search="search"
        :loading="loadingData" -->
        <template v-slot:item.cod_pago="{item}">
          <div class="d-flex align-center" :class="{'py-8': !$vuetify.breakpoint.mobile }">
            <v-icon
              left
              :color="item.status === 0 ? 'warning' : item.status === 1 ? 'icono' : item.status === 2 ? 'red' : ''"
              v-text="item.status === 0 ? 'mdi-alert' : item.status === 1 ? 'mdi-checkbox-marked-circle' : item.status === 2 ?'mdi-close-circle-outline' : ''"
            />
            <span
              class="font-weight-medium"
              :class="{'warning--text': item.status === 0, 'icono--text': item.status === 1, 'red--text': item.status === 2 }"
              v-text="item.cod_pago"
            />
          </div>
        </template>
        <template v-slot:item.fecha_recibido="{item}">
          <span class="font-weight-medium blue-grey--text">{{item.fecha_recibido | smartDate}}</span>
        </template>
        <template v-slot:item.cli_des="{item}">
          <span
            class="primary--text font-weight-bold"
            :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }"
            v-text="item.cli_des" />
          <span
            class="font-weight-bold ml-1"
            v-text="'('+ item.co_cli + ')'" />
        </template>
        <template v-slot:item.ven_des="{item}">
          <span
            class="blue-grey--text font-weight-medium"
            :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }"
            v-text="item.ven_des" />
          <span
            class="font-weight-bold ml-1"
            v-text="'('+ item.co_ven + ')'" />
        </template>
        <template v-slot:item.monto="{item}">
          <div class="d-flex justify-center">
            <span class="font-weight-bold black--text" style="font-size:16px">${{ item.monto | formatNumero | FormatCurrency}}</span>
          </div>
        </template>
        <template v-slot:item.iconos="{item}">
          <div class="d-flex flex-row">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="20"
                    color="blue-grey"
                    class="mx-2"
                    v-text="item.metodo_pago === 'efectivo' ? 'mdi-cash-multiple' : item.metodo_pago === 'transferencia' ? 'mdi-bank-transfer': ''"
                  />
                </template>
                <span>Metodo Pago: {{item.metodo_pago}}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="blue-grey--text font-weight-medium mx-2"
                    style="font-size: 18px"
                    v-text="item.moneda === 'dolares' ? '$' : item.moneda === 'bolivares' ? 'Bs.': ''"
                  />
                </template>
                <span>Moneda: {{item.moneda}}</span>
            </v-tooltip>
          </div>
        </template>


        <!-- DETALLES DEL PAGO  -->
          <!-- <template v-slot:expanded-item="{item, headers}">
            <td :colspan="headers.length">
              <item-cobro-detalles
                :pago="item" :key="`detailsPay_${item.cod_pago}`"
              />
            </td>
          </template> -->
        <!-- FIN DETALLES  -->
        <template #loading>
          <v-skeleton-loader
            type="table-tbody"
          ></v-skeleton-loader>
        </template>
        <template v-slot:no-data>
          <div class="text-center pa-4">
            <span class="blue-grey--text font-italic">No se encontraron Cobros Registrados</span>
          </div>
        </template>
      </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name:'ModalDetallesCobros',
  props:{
    value: Boolean,
    cobros:{
      type: Array,
      default: () => ([]),
    },
    details: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modalShow: this.value,
    }
  },
  watch: {
    modalShow (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.modalShow = val
    },
  },
  computed:{
    headerPayment () {
      // { text: 'Seleccionar', value: 'data-table-select', class: this.$vuetify.breakpoint.smAndUp ? 'header-data' : '' },
      return [
        { text: 'Código', value: 'cod_pago', class: 'header-data', width: '100' },
        { text: 'Recibido', value: 'fecha_recibido', class: 'header-data', width: '120'},
        // { text: 'Cod Vendedor', value: 'co_ven', class: 'header-data', width: '20', align: ' d-none'},
        {
          text: 'Vendedor',
          align: 'start',
          value: 'ven_des',
          class: 'header-data',
        },
        { text: 'Monto', value: 'monto', class: 'header-data px-0 d-flex justify-center align-center' },
        { text: '', value: 'iconos', class: 'header-data px-0', width: '50', align: this.details === true ? ' pl-0' : ' d-none'},
      ]
    },
  },
  methods: {
    cerrar () {
      this.modalShow = false;
    },
  },
  filters: {
    formatNumero(num) {
      return num !== null && num !== 'null' ? num.replace(/,/, '.') : '';
    }
  }
}
</script>
<style lang="sass">
.z-index-lg
  z-index: 1000 !important
</style>
